<script>
import {
  createPlaylist,
  updatePlaylist,
  updateStatus
} from "@/api/playlist/setters";
import ErrorService from "@/common/error.service";
import { getAttributesRead } from "@/common/config/acl/index.js";
import {
  FORM_PROGRAMS_PLAYLIST,
  FORM_ADVERTISEMENT_PLAYLIST,
  FORM_ADVANCED_PLAYLIST
} from "@/common/config/acl/programs/playlist/abac";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      body: {},
      programForm: {
        playlist: null,
        domain: [],
        tags: [],
        itunesSubCategories: []
      },
      isLoadingCreatingPlaylist: false,
      advertisementForm: {},
      advancedForm: {}
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    })
  },

  methods: {
    prepareBodyProgramStatus() {
      this.body = {
        homeDisplay: this.programForm.homeDisplay
      };
    },

    prepareBody() {
      const formProgam = this.$refs.program;
      const formAdvertisement = this.$refs.advertisement;
      const formAdvanced = this.$refs.advanced;
      const formEpisode = this.$refs.listing;

      this.body = {
        formProgam,
        formAdvertisement,
        formAdvanced
      };

      const categoriesItunes = [];
      if (formProgam.listItunesCategoriesSelected.length > 0) {
        formProgam.listItunesCategoriesSelected.forEach(element => {
          categoriesItunes.push(element);
        });
      }

      this.body = {
        name: formProgam.formData.name,
        title: formProgam.formData.title,
        copyright: formProgam.formData.author,
        domain: formProgam.domainObject.id,
        description: formProgam.formData.description,
        language: formProgam.formData.language,
        isExplicit: formProgam.formData.isExplicit,
        keywords: formProgam.formData.keywords,
        itunesSubCategories: categoriesItunes,
        type: formProgam.formData.type,
        authorLink: formProgam.formData.authorLink,
        summary: formProgam.formData.description,
        ownerName: formProgam.formData.author || "",
        ownerEmail: formProgam.formData.ownerEmail,
        author: formProgam.formData.author
      };

      if (this.isAdmin) {
        this.body.homeDisplay = this.programForm.homeDisplay;
        this.body.isForced = formAdvanced.formData.isForced;
        this.body.cluster = formAdvanced.formData.cluster;
        this.body.redirectTo = formAdvanced.formData.redirectTo;

        this.body.authorizedAds = formAdvertisement.formData.authorizedAds;
        this.body.authorizedAudioAds =
          formAdvertisement.formData.authorizedAudioAds;
        this.body.audioAdsBackfilled =
          formAdvertisement.formData.audioAdsBackfilled;
        this.body.audioAdsUrl = formAdvertisement.formData.audioAdsUrl;
        this.body.brand = formAdvertisement.formData.brand || null;

        const tagIABSelected = [];
        if (formAdvanced.tagIABSelected.length > 0) {
          formAdvanced.tagIABSelected.forEach(element => {
            tagIABSelected.push(element.id);
          });
        }

        const tagLangSelected = [];
        if (formAdvanced.tagLangSelected.length > 0) {
          formAdvanced.tagLangSelected.forEach(element => {
            tagLangSelected.push(element.id);
          });
        }

        this.body.tags = tagIABSelected.concat(tagLangSelected);

        if (
          formAdvertisement.checkCappingNativeAds &&
          formAdvertisement.cappingNativeAds !== ""
        ) {
          this.body.capping = {
            native_ad: {
              classic: {
                duration: parseInt(formAdvertisement.cappingNativeAds)
              }
            }
          };
        } else {
          this.body.capping = {
            native_ad: []
          };
        }

        if (
          formAdvertisement.checkCappingAudiosAds &&
          formAdvertisement.capingAudioAds !== ""
        ) {
          this.body.capping = {
            ...this.body.capping,
            audio_ad: {
              classic: {
                duration: parseInt(formAdvertisement.capingAudioAds)
              }
            }
          };
        } else {
          this.body.capping = {
            ...this.body.capping,
            audio_ad: []
          };
        }
      }

      if (formEpisode !== undefined) {
        this.body.episodes = formEpisode.allEpisodesCheck;
      }

      let formData = new FormData();

      if (this.typeForm === "playlist") {
        this.body.playlist = {
          name: formProgam.formData.name
        };
      }

      if (formProgam.formData.url.length > 0) {
        this.body.url = formProgam.formData.feedUrl;
      }

      if (formProgam.fileImage.name) {
        formData.append("file", formProgam.fileImage);
      }

      formData.append("data", JSON.stringify(this.body));

      this.body = formData;
    },

    setForm(data) {
      const programFromKeys = Object.keys(
        getAttributesRead(FORM_PROGRAMS_PLAYLIST, this.currentUser.roles)
      );

      programFromKeys.forEach(element => {
        this.programForm[element] = data[element];
      });

      const advertisementFormKeys = Object.keys(
        getAttributesRead(FORM_ADVERTISEMENT_PLAYLIST, this.currentUser.roles)
      );

      advertisementFormKeys.forEach(element => {
        this.advertisementForm[element] = data[element];
      });

      const advancedFormsKeys = Object.keys(
        getAttributesRead(FORM_ADVANCED_PLAYLIST, this.currentUser.roles)
      );

      advancedFormsKeys.forEach(element => {
        this.advancedForm[element] = data[element];
      });
    },

    async createProgram() {
      this.isLoadingCreatingPlaylist = true;
      this.isErrorSend = false;

      try {
        this.prepareBody();

        const response = await createPlaylist(this.body, this.axios);

        this.body = response;

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.MESSAGE_CREATE"
          ),
          variant: "success"
        });

        if (this.body.playlist !== null) {
          if (this.isCreating) {
            await this.$router.push({
              name: "settings_content_item",
              params: { id: response.id }
            });
          } else {
            this.$emit("reloadProgram");
          }
        } else {
          await this.$router.push({
            name: "settings_content_item",
            params: { id: response.id }
          });
        }
      } catch (error) {
        console.error("---ERROR-PLAYLIST---");
        console.error(error);
        ErrorService.displayErrorMsg(error);
        this.isErrorSend = true;
      }

      this.isLoadingCreatingPlaylist = false;
    },

    async updateProgram() {
      this.isLoadingCreatingPlaylist = true;
      this.isErrorSend = false;

      try {
        this.prepareBody();

        const response = await updatePlaylist(
          this.body,
          this.getProgramId,
          this.axios
        );
        this.body = response;

        if (this.body.playlist !== null) {
          if (this.isCreating) {
            await this.$router.push({
              name: "settings_content_item",
              params: { id: response.id }
            });
          } else {
            this.$emit("reloadProgram");
          }
        } else {
          this.reloadProgram();
        }

        this.isLoadingCreatingPlaylist = false;

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.MESSAGE_UPDATE"
          ),
          variant: "success"
        });
      } catch (error) {
        console.error("---ERROR-PLAYLIST---");
        console.error(error);
        ErrorService.displayErrorMsg(error);
        this.isErrorSend = true;
        this.isLoadingCreatingPlaylist = false;
      }
    },

    reloadProgram() {
      this.loadConfigurations();
      this.loadEpisodesFromPrograms({ page: 1, limit: 10, search: "" });
    },

    async updateProgramStatus() {
      this.isLoadingCreatingPlaylist = true;
      this.isErrorSend = false;

      try {
        this.prepareBodyProgramStatus();

        await updateStatus(this.body, this.getProgramId, this.axios);

        this.showToast({
          title: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS_PLAYLIST.TITLE"
          ),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS_PLAYLIST.MESSAGE_UPDATE"
          ),
          variant: "success"
        });
      } catch (error) {
        console.error("---ERROR-PLAYLIST---");
        console.error(error);
        ErrorService.displayErrorMsg(error);
        this.isErrorSend = true;
      }

      this.isLoadingCreatingPlaylist = false;
    }
  }
};
</script>
