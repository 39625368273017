<template>
  <perfect-scrollbar class="container-podcast card ml-md-10 mt-5 mt-md-0 px-5">
    <div
      v-if="isLoadingPodcast"
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <b-spinner label="Spinning" variant="primary"></b-spinner>
      <span class="font-weight-bold"
        >{{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LOADING") }}...</span
      >
    </div>

    <div v-if="!isLoadingPodcast && !isCreating" class="row">
      <div class="col-md-12">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <blockquote class="blockquote mt-5">
            <h1>{{ programForm.name }}</h1>
            <footer class="blockquote-footer">{{ programForm.title }}</footer>
            <div v-if="!isFormPlaylist" class="alert alert-success mt-3 mr-12">
              <p class="font-italic my-auto">
                This podcast has been set up from your hosting provider. To
                modify the settings of this podcast, please visit your hosting
                account.
              </p>
            </div>
          </blockquote>
          <img
            :src="programForm.image"
            class="rounded-lg float-end mt-lg-10 mt-md-10 mt-0 mb-lg-0 mb-md-5 mb-sm-5 my-5 mr-5"
            width="200"
            height="200"
            alt="Thumbnails podcasts"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!isLoadingPodcast"
      class="d-flex flex-column flex-md-row"
      :class="{ 'mt-5': isCreating }"
    >
      <b-overlay :show="showOverlay" rounded="sm" class="text-center">
        <b-form-radio-group
          v-model="programForm.homeDisplay"
          id="btn-radios-group"
          class="ml-lg-5 mb-2 mb-md-0 mr-md-2"
          :options="options"
          name="radio-btn-outline"
          buttons
          @change="updateStatus"
        ></b-form-radio-group>
      </b-overlay>

      <b-button
        v-if="!isCreating"
        type="button"
        class="btn btn-outline-dark mb-2 mb-md-0 mr-md-2"
        @click="
          dialog = true;
          loadEpisodesFromPrograms(1);
        "
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
        {{ $tc("ADVANCED_SETTINGS.CONTENT.EPISODES_LIST") }}
      </b-button>
      <v-dialog
        v-model="dialog"
        width="900"
        width-max="900px"
        min-height="300px"
        eager
      >
        <ListingEpisodesV2
          :show="dialog"
          :header="headers"
          :items="items"
          :isLoading="isLoadingEpisodesFromPrograms"
          :totalItems="totalItemsEpisodes"
          :program="programForm"
          @currentPage="currentPageEpisodesList"
          @loadEpisodes="loadEpisodesFromPrograms"
        />
      </v-dialog>

      <b-button
        v-if="!isCreating && isFormPlaylist"
        class="btn btn-outline-dark"
        role="button"
        :href="programForm.platformUrl"
        target="_blank"
      >
        <v-icon>mdi-podcast</v-icon>
        {{ $tc("ADVANCED_SETTINGS.CONTENT.DOMAIN_PROGRAM") }}
      </b-button>
      <!-- <a
          class="btn btn-outline-dark"
          role="button"
          :href="programForm.feedUrl"
          target="_blank"
        >
          <v-icon>mdi-rss</v-icon>
          {{ $tc("ADVANCED_SETTINGS.CONTENT.RSS_FEED") }}
        </a> -->
    </div>

    <div v-if="!isLoadingPodcast" class="mt-O">
      <b-alert
        v-model="isErrorSend"
        variant="danger"
        dismissible
        class="mt-3 mx-4"
      >
        {{ $tc("ADVANCED_SETTINGS.CONTENT.ERROR_SEND") }}
        <a href="mailto:support@voxeus.com" class="ml-1 text-white"
          ><u>support@voxeus.com</u></a
        >
      </b-alert>

      <ProgramForm
        :form="programForm"
        :programId="getProgramId"
        :domainsList="getDomains"
        :isCreating="isCreating"
        :typeForm="typeForm"
        :isFormPlaylist="isFormPlaylist"
        :isCanCheck="isCanCheck"
        :currentUser="currentUser"
        @changeDomain="selectDomain"
        ref="program"
      />

      <ListingEpisode
        v-if="isFormPlaylist"
        :isCreating="isCreating"
        :programId="getProgramId"
        :domainId="domainId"
        :episodesLists="episodesLists"
        :totalItems="totalsEpisodesList"
        :isLoading="isLoadingEpisodes"
        :episodesSelected="episodesSelected"
        ref="listing"
        @loadItems="changePageEpisodesList"
        @reloadProgram="reloadProgram"
        @errorEpisodes="
          (isErrorSend = true), (isLoadingCreatingPlaylist = false)
        "
      />

      <AdvertisementForm
        v-if="isAdmin"
        :form="advertisementForm"
        :isCanCheck="isCanCheck"
        :programId="getProgramId"
        :domains="programForm.programDomains"
        :program="programForm"
        :currentUser="currentUser"
        ref="advertisement"
      />

      <AdvancedSettings
        v-if="isAdmin"
        :form="advancedForm"
        :programId="getProgramId"
        :currentUser="currentUser"
        ref="advanced"
      />

      <b-modal
        id="modal-delete-program"
        :title="$tc('ADVANCED_SETTINGS.CONTENT.DELETE.TITLE')"
        hide-header-close
      >
        <p class="my-4">{{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.LABEL") }}</p>

        <template #modal-footer="{ close }">
          <button
            class="btn border rounded mr-3"
            :disabled="isLoading"
            @click="close"
          >
            {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.CANCEL") }}
          </button>

          <button
            class="btn btn-danger mr-3"
            :disabled="isLoading"
            @click="deleteProgram(close)"
          >
            <template v-if="!isLoading">
              {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.SUBMIT") }}
            </template>

            <template v-if="isLoading">
              <b-spinner label="spinning"></b-spinner>
            </template>
          </button>
        </template>
      </b-modal>

      <div class="d-flex justify-content-center my-5">
        <b-button
          v-if="!isCreating"
          class="btn btn-danger mr-3"
          :disabled="isLoading || !hasAccess('deleted', 'write')"
          v-b-modal.modal-delete-program
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.CONTENT.LABEL_DELETE") }}
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </b-button>
        <v-icon
          v-if="!hasAccess('deleted', 'write') && !isCreating"
          class="badge badge-light"
          :title="$tc('ADVANCED_SETTINGS.CONTENT.TOOLTIP_DELETE')"
          >mdi-help-circle-outline</v-icon
        >
        <button
          class="btn btn-primary"
          style="width: 121px"
          @click="submit"
          :disabled="isLoading"
        >
          <template v-if="!isLoading">
            {{
              isCreating
                ? $tc("ADVANCED_SETTINGS.CONTENT.SUBMIT")
                : $tc("ADVANCED_SETTINGS.CONTENT.SAVE")
            }}
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { getProgramsFromV2 } from "@/api/configurations/getters";
import { mapGetters } from "vuex";
import ProgramForm from "@/components/settings/formsPogram/ProgramForm";
import AdvertisementForm from "@/components/settings/formsPogram/AdvertisementForm";
import AdvancedSettings from "@/components/settings/formsPogram/AdvancedSettingsForm";
import ListingEpisode from "@/components/settings/formsPogram/ListingEpisode";
import ProgramLogic from "@/components/settings/mixins/ProgramLogic";
import {
  getAllEpisodes,
  getEpisodesFromPrograms
} from "@/api/programs/getters.js";
import ListingEpisodesV2 from "@/components/settings/ListingEpisodesV2";
import { deleteProgram } from "@/api/programs/setters.js";
import {
  headersEpisodeList,
  limitPerPage
} from "@/components/settings/formsPogram/programs.config.js";
import { hasAttributeAccess } from "@/common/config/acl";
import { FORM_PROGRAMS } from "@/common/config/acl/programs/abac";
import auth from "@/store/auth.module";

export default {
  mixins: [ProgramLogic],

  components: {
    ProgramForm,
    AdvertisementForm,
    AdvancedSettings,
    ListingEpisode,
    ListingEpisodesV2
  },

  data() {
    return {
      user: auth.state.user,
      showOverlay: false,
      options: [
        { text: "Online", value: true },
        { text: "Offline", value: false }
      ],
      typeForm: "",
      isErrorSend: false,
      isLoadingPodcast: false,
      isLoadingConfiguration: false,
      isLoadingCreatingPlaylist: false,
      isCanCheck: false,
      dialog: false,
      headers: headersEpisodeList,
      items: [],
      episodesLists: [],
      isLoadingEpisodes: false,
      isLoadingEpisodesFromPrograms: false,
      totalItemsEpisodes: 0,
      totalsEpisodesList: 0,
      currentPageEpisodesList: 1,
      limitPerPage,
      episodesSelected: [],
      domainId: ""
    };
  },

  async mounted() {
    if (this.getProgramId && !this.isCreating) {
      await this.loadConfigurations();
    }

    if (this.getFormType) {
      this.changeTypeForm(this.getFormType);
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      isAdmin: "isAdmin"
    }),

    getProgramId() {
      let params = this.$route.params.id;

      if (params === "create") {
        params = null;
      }

      return params;
    },

    isCreating() {
      return this.$route.path.includes("create");
    },

    isLoading() {
      return this.isLoadingConfiguration || this.isLoadingCreatingPlaylist;
    },

    isFormPlaylist() {
      return (
        this.typeForm === "playlist" || this.$route.query?.type === "playlist"
      );
    },

    getDomains() {
      return this.currentUser.domains;
    },

    hasPlaylist() {
      return this.programForm.playlist !== null;
    },

    getURLProgram() {
      return `https://${this.programForm.domain.host}`;
    },

    getFormType() {
      return this.$route.query.type;
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_PROGRAMS,
        this.user.roles,
        field,
        accessType
      );
    },
    selectDomain(domain) {
      this.domainId = domain;

      if (this.typeForm === "playlist") {
        this.loadGetAllEpisodes({ page: 1, search: "", domain: domain });
      }
    },

    changeTypeForm(type) {
      this.typeForm = type;
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    },

    async deleteProgram(close) {
      this.isLoadingConfiguration = true;

      try {
        await deleteProgram(this.programForm.id, this.axios);

        close();

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.MESSAGE_DELETE"
          ),
          variant: "success"
        });
        this.$emit("loadPrograms", true);
        this.$router.push({ name: "settings_content_item_create" });
      } catch (error) {
        console.error("---ERROR-DELETE-PROGRAM---");
        console.error(error);
      }

      this.isLoadingConfiguration = false;
    },

    async loadConfigurations() {
      this.isLoadingPodcast = true;
      this.typeForm = "program";
      this.episodesSelected = [];

      try {
        const response = await getProgramsFromV2(this.getProgramId, this.axios);

        if (response.playlist) {
          this.typeForm = "playlist";

          response.programEpisodes.forEach(element =>
            this.episodesSelected.push(element.episode.id)
          );
        }

        this.setForm(response);
      } catch (error) {
        console.error("---ERROR-CONFIGURATIONS---");
        console.error(error);
      }

      this.isLoadingPodcast = false;
    },

    async loadEpisodesFromPrograms({ page = 1, search = "" }) {
      this.isLoadingEpisodesFromPrograms = true;

      try {
        const response = await getEpisodesFromPrograms(
          { page, limit: this.limitPerPage, search },
          this.getProgramId,
          this.axios
        );
        this.items = response.data;
        this.totalItemsEpisodes = response.total_items;
        this.$root.$emit("currentPage", page);
      } catch (error) {
        console.error("---ERROR-EPISODES-PROGRAM---");
        console.error(error);
      }

      this.isLoadingEpisodesFromPrograms = false;
    },

    async loadGetAllEpisodes({
      page = 1,
      search = "",
      domain = "",
      sortField = "",
      sortDirection = true
    }) {
      this.isLoadingEpisodes = true;
      this.episodesLists = [];

      try {
        const filters = {
          page: page,
          limit: this.limitPerPage
        };

        if (search.length > 0) {
          filters.search = search;
        }

        if (domain.length > 0) {
          filters.domain = domain;
        }

        if (sortField.length > 0) {
          filters.sortField = sortField;
        }

        sortDirection = sortDirection.toString();
        if (sortDirection.length > 0) {
          filters.sortDirection = sortDirection;
        }

        if (this.programForm.playlist?.id) {
          filters.playlist = this.programForm.playlist.id;
        }

        const response = await getAllEpisodes(filters, this.axios);
        this.episodesLists = response.data;

        this.totalsEpisodesList = response.total_items;
        this.currentPageEpisodesList = response.current_page;
      } catch (error) {
        console.error("---ERROR-EPISODES---");
        console.error(error);
      }

      this.isLoadingEpisodes = false;
    },

    resetData() {
      const refs = Object.keys(this.$refs);
      this.episodesSelected = [];
      this.items = [];

      refs.forEach(key => {
        if (this.$refs[key] && key !== "program") {
          this.$refs[key].resetData();
        }
      });
    },

    async changePageEpisodesList({
      page = 1,
      search = "",
      domain = "",
      sortField = "",
      sortDirection = ""
    }) {
      await this.loadGetAllEpisodes({
        page,
        search,
        domain,
        sortField,
        sortDirection
      });
    },

    submit() {
      this.$emit("submit");
      this.isCanCheck = true;
      const refProgram = this.$refs.program;
      const refAdvertisement = this.$refs.advertisement;
      let refListings = {
        hasErrors: false
      };

      if (this.isFormPlaylist) {
        refListings = this.$refs.listing;
      }

      //Obligate to delay because of the change "isCanCheck"
      setTimeout(() => {
        let conditions = !refProgram.hasErrors;

        if (this.isAdmin) {
          conditions =
            !refProgram.hasErrors &&
            !refAdvertisement.hasErrors &&
            !refListings.hasErrors;
        }

        if (conditions) {
          if (this.getProgramId) {
            this.updateProgram().catch(error => {
              console.error("---ERROR-UPDATE_PROGRAM---");
              console.error(error);
            });
          } else {
            this.createProgram();
          }
        }
      }, 20);
    },

    async updateStatus() {
      if (this.getProgramId) {
        this.showOverlay = true;
        this.updateProgramStatus()
          .catch(error => {
            console.error("---ERROR-UPDATE__STATUS---");
            console.error(error);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.container-podcast {
  width: 100%;
  min-height: 300px;
}

.card-choice:hover {
  background: #f8f9fa;
  color: #3699ff;
  cursor: pointer;
}

@media (min-width: 960px) {
  .container-podcast {
    width: 100%;
  }
}

.fa {
  font-size: 32px;
  color: #9c47fc;
  display: block;
  background: -webkit-linear-gradient(#9c47fc, #356ad2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fontawesome-icon-list {
  text-align: center;
}

.blockquote-footer {
  font-size: 15px;
}

.badge {
  position: relative;
  padding: 0;
  margin: 15px;
  top: -24px;
  left: -41px;
  border-radius: 50%;
}
</style>
