import { get } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";

export const getBrands = (companyId, axios, filters = null) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.path,
      ressources: [
        {
          name: companyId,
          value: ENTITY_NAME_IN_URI.BRANDS.path
        }
      ]
    },
    filters,
    axios
  );
